import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import {
  PRIMARY_LANGUAGE,
  SECONDARY_LANGUAGE,
  DEFAULT_PRIMARY_LANGUAGE,
  DEFAULT_SECONDARY_LANGUAGE,
} from 'src/constants';
import locales from 'src/locales';

const detectorOptions = {
  caches: ['localStorage', 'cookie'],
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupQuerystring: 'lng',
  order: ['localStorage', 'navigator', 'cookie', 'htmlTag', 'querystring', 'path'],
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: detectorOptions,
    defaultNS: 'common',
    fallbackLng: PRIMARY_LANGUAGE ?? SECONDARY_LANGUAGE ?? DEFAULT_PRIMARY_LANGUAGE ?? DEFAULT_SECONDARY_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    resources: locales,
    lng: PRIMARY_LANGUAGE ?? SECONDARY_LANGUAGE ?? DEFAULT_PRIMARY_LANGUAGE ?? DEFAULT_SECONDARY_LANGUAGE,
  });

export default i18n;
