const primary = (window as any)._env_.REACT_APP_PRIMARY_LANGUAGE ?? process.env.REACT_APP_PRIMARY_LANGUAGE;
const secondary = (window as any)._env_.REACT_APP_SECONDARY_LANGUAGE ?? process.env.REACT_APP_SECONDARY_LANGUAGE;

export function isValidLanguage(language: string): boolean {
  return ['ru', 'en'].includes(language);
}

export const PRIMARY_LANGUAGE = isValidLanguage(primary) ? primary : null;
export const SECONDARY_LANGUAGE = isValidLanguage(secondary) ? secondary : null;

export const DEFAULT_PRIMARY_LANGUAGE = 'ru';
export const DEFAULT_SECONDARY_LANGUAGE = 'en';
