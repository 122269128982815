import { DisplayType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';

declare global {
  interface Window {
    _env_: {
      REACT_APP_LOGO_FULL_SRC?: string;
    };
  }
}

export const DEFAULT_DISPLAY_TYPE = {
  displayType: DisplayType.static,
};

export const PRODUCT_NAME = 'godashboard';

export const LOGO_SRC = window._env_.REACT_APP_LOGO_FULL_SRC || process.env.REACT_APP_LOGO_FULL_SRC;
