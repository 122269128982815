import { UpdatesButton } from '@go-updates/update-notification-frontend/dist';
import { FC, useContext, useEffect, useState } from 'react';

import { DarkModeIcon, LightModeIcon } from 'src/assets/icons';
import { Applications, Burger, Language, Logo, NavMobile, UserInfo } from 'src/components';
import { PATHS } from 'src/constants';
import { ThemeContext } from 'src/context/themeContext';
import { getProducts, getUserInfo, ICard, IPersonInfo } from 'src/services/AuthService';
import { showErrorMessage } from 'src/utils';

import agentUpdates from '../../agent/agentUpdates';

import {
  HeaderApp,
  HeaderCol,
  HeaderDataDesktop,
  HeaderRight,
  LogoWrapper,
  MobileUpdatesButtonWrapper,
  SwitchTheme,
  UpdatesButtonWrapper,
} from './Header.styled';

const Header: FC = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  const [person, setPerson] = useState<IPersonInfo | null>(null);
  const [products, setProducts] = useState<ICard[] | null>(null);
  const [isShowNav, setIsShowNav] = useState<boolean>(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await getUserInfo();
        setPerson(user.data);

        const products = await getProducts();
        setProducts(products.data);
      } catch (error: any) {
        showErrorMessage(error?.message);
      }
    };

    getUserData();
  }, []);

  const onToggleNav = () => {
    setIsShowNav((prevShow) => !prevShow);
  };

  return (
    <HeaderApp>
      <HeaderCol>
        <LogoWrapper to={PATHS.root}>
          <Logo />
        </LogoWrapper>

        <HeaderRight>
          <SwitchTheme
            checkedChildren={<DarkModeIcon />}
            unCheckedChildren={<LightModeIcon />}
            defaultChecked={isDarkMode}
            onChange={toggleTheme}
          />
          <MobileUpdatesButtonWrapper>
            <UpdatesButton
              settings={{
                theme: isDarkMode ? 'dark' : 'light',
                updatesServiceAgent: agentUpdates,
              }}
              onError={(message, e) => {
                showErrorMessage(message);
                console.error(e);
              }}
            />
          </MobileUpdatesButtonWrapper>
          <Burger onToggleNav={onToggleNav} />
        </HeaderRight>
      </HeaderCol>

      <HeaderDataDesktop>
        <Applications products={products} />
        <UpdatesButtonWrapper>
          <UpdatesButton
            settings={{
              theme: isDarkMode ? 'dark' : 'light',
              updatesServiceAgent: agentUpdates,
            }}
            onError={(message, e) => {
              showErrorMessage(message);
              console.error(e);
            }}
          />
        </UpdatesButtonWrapper>
        <Language />
        <UserInfo person={person} />
      </HeaderDataDesktop>

      <NavMobile isVisible={isShowNav} onClose={onToggleNav} person={person} products={products} />
    </HeaderApp>
  );
};

export default Header;
