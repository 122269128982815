import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PRIMARY_LANGUAGE, SECONDARY_LANGUAGE } from 'src/constants';
import { cleanPageMapInAllPages } from 'src/store/pages';
import { getLocale } from 'src/utils';

import { DropdownLang, MenuItem } from './Language.styled';

const languageLabelMap: Record<string, string> = {
  ru: 'RU',
  en: 'EN',
};

const languageMap: Record<string, string> = {
  ru: 'ru',
  'ru-RU': 'ru',
  en: 'en',
  'en-US': 'en',
};

function getLanguageOptions(): { key: string; label: string }[] {
  try {
    const primaryLanguage = languageLabelMap[PRIMARY_LANGUAGE];
    const secondaryLanguage = languageLabelMap[SECONDARY_LANGUAGE];

    if (primaryLanguage && secondaryLanguage) {
      return [
        { key: PRIMARY_LANGUAGE, label: primaryLanguage },
        { key: SECONDARY_LANGUAGE, label: secondaryLanguage },
      ];
    }

    if (primaryLanguage) {
      return [{ key: PRIMARY_LANGUAGE, label: primaryLanguage }];
    }

    if (secondaryLanguage) {
      return [{ key: SECONDARY_LANGUAGE, label: secondaryLanguage }];
    }

    return [
      { key: 'ru', label: 'RU' },
      { key: 'en', label: 'EN' },
    ];
  } catch (e) {
    console.error(e);

    return [
      { key: 'ru', label: 'RU' },
      { key: 'en', label: 'EN' },
    ];
  }
}

const Language: FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = getLocale();

  const options = useRef(getLanguageOptions());

  const langLabel = languageLabelMap?.[languageMap?.[language]];

  const onClickHandler = (data: { key: string }) => {
    i18n.changeLanguage(data.key);
    dispatch(cleanPageMapInAllPages());
  };

  if (options.current.length <= 1) {
    return null;
  }

  const menu = (
    <Menu onClick={onClickHandler}>
      {options.current.map(({ key, label }) => (
        <MenuItem key={key}>{label}</MenuItem>
      ))}
    </Menu>
  );

  return (
    <DropdownLang overlay={menu} trigger={['click']}>
      <div>
        {langLabel} <DownOutlined />
      </div>
    </DropdownLang>
  );
};

export default Language;
