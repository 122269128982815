import axios from 'axios';

import { GOHUB_API_URL, GOHUB_REDIRECT_URL } from 'src/constants';
import { getToken, hasToken, removeToken } from 'src/utils';

const agentGoHub = axios.create({
  baseURL: GOHUB_API_URL,
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

agentGoHub.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : 408;

    if (status >= 500) {
      window.console.error(error.toString());
    }

    if (status === 401) {
      removeToken();
      window.location.href = GOHUB_REDIRECT_URL;
    }

    return Promise.reject(error);
  }
);

agentGoHub.interceptors.request.use(
  (config) => {
    const updConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: hasToken() ? `Bearer ${getToken()}` : config.headers?.Authorization,
      },
    };

    if (updConfig.url?.length && !updConfig.url.includes('?') && updConfig.url[updConfig.url.length - 1] !== '/') {
      updConfig.url += '/';
    }

    return updConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default agentGoHub;
