import { FC } from 'react';

import { LOGO_SRC } from 'src/constants';

import { LogoContainer, LogoImage } from './Logo.styled';

const Logo: FC = () => (
  <LogoContainer>
    <LogoImage src={LOGO_SRC} alt="Logo" />
  </LogoContainer>
);

export default Logo;
