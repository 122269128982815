import { FilterOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HandlerProps, ReflexContainer, ReflexSplitter } from 'react-reflex';

import { AnalyticsChart, Loader } from 'src/components';
import { CommonFiltersForm, IndicatorsFiltersForm } from 'src/components/forms';
import { DisplayType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import { RangePeriod } from 'src/components/RangePicker/RangePicker.types';
import { useDebounce, useWindowSize } from 'src/hooks';
import { getFiltersList } from 'src/services/AnalyticsService';
import { AnalyticsFilters, FiltersValues } from 'src/services/AnalyticsService/AnalyticsService.types';
import { getPageById, updatePage } from 'src/services/PageService';
import {
  addOrUpdateFrontend,
  deleteDisplayType,
  toggleSeriesVisible,
} from 'src/services/PageService/PageService.helpers';
import { FrontendValue, GraphDisplayTypes, PageResponse } from 'src/services/PageService/PageService.types';
import { getFiltersState, setFiltersAction } from 'src/store/analytics';
import {
  getPageMapState,
  updateChartHiddenSeries,
  updatePageAction,
  updateShowingValuesOnChart,
} from 'src/store/pages';
import {
  getEndPeriodDate,
  getMinUpdatedPeriod,
  getObjectKey,
  parseDataToFilters,
  prepareDataGraphs,
  prepareFilters,
  showErrorMessage,
} from 'src/utils';

import { EmptyContent } from '../Dashboard/Dashboard.styled';

import { clearFrontend, getIsGroupedCategoriesCharts, sortGraphsByFixed } from './Analytics.helpers';
import {
  AnalyticLoading,
  AnalyticsWrapper,
  ChartContainer,
  ContentPadding,
  ContentWrapper,
  DrawerWrapper,
  FilterLabel,
  FilterPanel,
  ToggleLeftPanel,
  ToggleRightPanel,
} from './Analytics.styled';
import { AnalyticsProps } from './Analytics.types';

const dateFormat = 'DD.MM.YYYY';

const Analytics: FC<AnalyticsProps> = ({ id }) => {
  const debounce = useDebounce();
  const windowSize = useWindowSize();
  const { t } = useTranslation('dashboard');

  const filters = useSelector(getFiltersState);
  const dispatch = useDispatch();

  const { pageMap, hiddenChartSeries, displayTypes } = useSelector(getPageMapState(id));
  const sortedGraphs = useMemo(() => sortGraphsByFixed(pageMap?.graphs), [pageMap]);
  const hasCharts = !!pageMap?.graphs?.some((graph) => !!graph?.data?.charts?.length);

  const [isVisibleLeftPanel, setIsVisibleLeftPanel] = useState(window.innerWidth >= 768);
  const [flexLeftPanel, setFlexLeftPanel] = useState(0.187113);
  const [isVisibleRightPanel, setIsVisibleRightPanel] = useState(window.innerWidth >= 768);
  const [flexRightPanel, setFlexRightPanel] = useState(0.187113);

  const [isLoadFilters, setIsLoadFilters] = useState<boolean>(!filters);
  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [isAnalyticLoading, setIsAnalyticLoading] = useState<boolean>(false);

  const [filterValues, setFilterValues] = useState<FiltersValues>(parseDataToFilters(pageMap?.graphs || [], filters));
  const pageLoaded = useMemo(() => !!pageMap, [pageMap]);

  const frontendUpdatesPromiseRef = useRef<Promise<AxiosResponse<PageResponse>>>();

  const onToggleLeftPanel = () => {
    setIsVisibleLeftPanel((prev) => !prev);
    setTimeout(() => {
      setFlexLeftPanel((prev) => prev - 0.0000001);
    }, 0);
  };

  const onStopResizeLeftPanel = ({ component }: HandlerProps) => {
    setFlexLeftPanel(component.props.flex || 0.20561);
  };

  const onToggleRightPanel = () => {
    setIsVisibleRightPanel((prev) => !prev);
    setTimeout(() => {
      setFlexRightPanel((prev) => prev - 0.0000001);
    }, 0);
  };

  const onStopResizeRightPanel = ({ component }: HandlerProps) => {
    setFlexRightPanel(component.props.flex || 0.20561);
  };

  const onResetIndicators = () => {
    const updatedFilters = filterValues?.filters?.filter((item) => !item.keyIndicator);
    setFilterValues({ ...filterValues, filters: updatedFilters, indicators: [] });
  };

  const fetchFiltersList = useCallback(
    async (filterValues?: FiltersValues) => {
      setIsLoadFilters(true);
      try {
        const notEmptyFilters = prepareFilters(filterValues?.filters || []);

        const { data } = await getFiltersList(notEmptyFilters || []);

        // Remove updated unavailable filter values from current filter values.
        // Current filter values may contain values that are unavailable due to the deletion of values
        // from dependent filters.
        if (filterValues?.filters) {
          const values = filterValues.filters.map((filter) => {
            const foundIndicator = data.indicators.find(({ key }) => key === filter.keyIndicator);

            if (foundIndicator?.filters) {
              const foundFilter = foundIndicator.filters.find(({ key }) => key === filter.key);
              const availableVariantsKeys = foundFilter?.variants?.reduce(
                (availableVariants: (string | number)[], currentVariant) => {
                  return currentVariant.is_available ? [...availableVariants, currentVariant.key] : availableVariants;
                },
                []
              );

              return {
                ...filter,
                values_list:
                  availableVariantsKeys && filter?.values_list
                    ? filter.values_list.filter((value) => availableVariantsKeys.includes(value))
                    : filter?.values_list,
              };
            }

            return filter;
          });

          setFilterValues({
            ...filterValues,
            filters: values,
          });
        }

        dispatch(setFiltersAction(data));
      } catch (error: any) {
        dispatch(setFiltersAction(null));
        showErrorMessage(error?.message);
      }
      setIsLoadFilters(false);
    },
    [dispatch]
  );

  const onChangeShowingValuesOnChart = useCallback(
    (showingValuesOnChart: boolean, graphKey: string) => {
      const updatedFrontend = addOrUpdateFrontend(
        { key: graphKey, value: showingValuesOnChart },
        pageMap?.frontend,
        'showing_values_on_chart'
      );

      dispatch(updateShowingValuesOnChart(id, graphKey, showingValuesOnChart));

      const prepareData = prepareDataGraphs(filterValues);

      debounce(() => {
        const promise = updatePage(
          id,
          {
            page_map: {
              layout: {},
              graphs: prepareData,
              frontend: updatedFrontend,
            },
          },
          true
        );

        frontendUpdatesPromiseRef.current = promise;

        promise.then(({ data }) => {
          if (frontendUpdatesPromiseRef.current === promise) {
            dispatch(updatePageAction(id, data));
          }
        });
      }, 500);
    },
    [debounce, dispatch, filterValues, id, pageMap?.frontend]
  );

  const updatePeriodDatesForDynamicDisplayTypes = (updatePeriod: string, key: string, filterValues: FiltersValues) => {
    const intervalFilter = filterValues?.filters?.find((filter) => filter.keyIndicator === key);
    if (!intervalFilter) {
      filterValues?.filters?.push({
        filter_rule_type: 'interval',
        filter_type: 'date',
        key: '28c84bd9-f0c7-4098-ba7c-b8f41bfb7dc4',
        keyIndicator: key,
        key_datatype: 'date',
        value_from: '',
        value_to: '',
      });
    }
    const intervalFilterNew = filterValues?.filters?.find((filter) => filter.keyIndicator === key);

    if (intervalFilterNew) {
      if (updatePeriod === RangePeriod.day) {
        intervalFilterNew.value_from = moment().startOf('month').format(dateFormat);
        intervalFilterNew.value_to = moment().endOf('month').format(dateFormat);
      }

      if (
        updatePeriod === RangePeriod.month ||
        updatePeriod === RangePeriod.quarter ||
        updatePeriod === RangePeriod.year
      ) {
        intervalFilterNew.value_from = moment().startOf('year').format(dateFormat);
        intervalFilterNew.value_to = moment().endOf('year').format(dateFormat);
      }
    }
  };

  const onChangeDisplayType = (
    key: string | undefined,
    displayType: DisplayType,
    updatePeriod: string | null | undefined
  ) => {
    if (key) {
      if (displayType === DisplayType.static && pageMap?.frontend) {
        const newFrontend = deleteDisplayType(pageMap.frontend, key);
        const prepareData = prepareDataGraphs(filterValues);

        setIsAnalyticLoading(true);

        updatePage(
          id,
          {
            page_map: {
              layout: {},
              graphs: prepareData,
              frontend: newFrontend,
            },
          },
          true
        )
          .then(({ data }) => {
            dispatch(updatePageAction(id, data));
          })
          .finally(() => {
            setIsAnalyticLoading(false);
          });
      } else {
        const newFrontendValue = {
          key,
          value: { displayType, updatePeriod },
          hash: getObjectKey({ displayType, updatePeriod }),
        };

        const updatedFrontend = addOrUpdateFrontend(newFrontendValue, pageMap?.frontend, 'graph_display_types');

        if (displayType === DisplayType.dynamic && updatePeriod) {
          const indicatorFilterValues = filterValues?.indicators?.find((indicator) => indicator.key === key);
          if (indicatorFilterValues) {
            indicatorFilterValues.is_cumulative = false;
          }

          updatePeriodDatesForDynamicDisplayTypes(updatePeriod, key, filterValues);
        }

        const preparedData = prepareDataGraphs(filterValues);
        setIsAnalyticLoading(true);

        updatePage(
          id,
          {
            page_map: {
              layout: {},
              graphs: preparedData,
              frontend: updatedFrontend,
            },
          },
          true
        )
          .then(({ data }) => {
            dispatch(updatePageAction(id, data));
          })
          .finally(() => {
            setIsAnalyticLoading(false);
          });
      }
    }
  };

  const onToggleChartSeries = useCallback(
    (key: string, seriesName: string, visible?: boolean) => {
      const newHiddenSeries = toggleSeriesVisible(hiddenChartSeries || [], key, seriesName, visible);
      const updatedSeries = newHiddenSeries?.find((hiddenSeries) => hiddenSeries.key === key);

      if (updatedSeries) {
        dispatch(updateChartHiddenSeries(id, key, updatedSeries));
      }

      debounce(() => {
        const promise = updatePage(
          id,
          {
            page_map: {
              ...pageMap,
              graphs: pageMap?.graphs || [],
              frontend: {
                ...pageMap?.frontend,
                hidden_chart_series: newHiddenSeries,
              },
            },
          },
          true
        );

        frontendUpdatesPromiseRef.current = promise;

        promise.then(({ data }) => {
          if (frontendUpdatesPromiseRef.current === promise) {
            dispatch(updatePageAction(id, data));
          }
        });
      }, 500);
    },
    [id, dispatch, hiddenChartSeries, debounce, pageMap]
  );

  const fetchPageData = useCallback(
    async (id: number, filters: AnalyticsFilters) => {
      if (isPageLoading) {
        return;
      }

      try {
        setPageLoading(true);

        const { data: pageData } = await getPageById(id, true);

        const dataFilters = parseDataToFilters(pageData.page_map?.graphs || [], filters);

        await fetchFiltersList(dataFilters);

        dispatch(updatePageAction(id, pageData));
      } finally {
        setPageLoading(false);
      }
    },
    [dispatch, fetchFiltersList, isPageLoading]
  );

  useEffect(() => {
    if (filterValues) {
      setIsAnalyticLoading(true);

      displayTypes?.forEach((frontendValue) => {
        const graphDisplayType: GraphDisplayTypes = frontendValue.value as GraphDisplayTypes;
        const key = frontendValue.key;
        const displayType = graphDisplayType.displayType;

        const indicatorFilterValues = filterValues.indicators?.find((indicator) => indicator.key === key);
        const indicatorFilters = filters?.indicators.find((indicator) => indicator.key === key);

        if (indicatorFilterValues && indicatorFilters) {
          const selectedAxis = indicatorFilters?.axes.find((axis) => axis.key === indicatorFilterValues.axes);
          const updatePeriod =
            selectedAxis &&
            selectedAxis.axis_groups.find((group) => group.key === indicatorFilterValues?.axis_groups)?.display_name;

          if (!updatePeriod) {
            const dt = pageMap?.frontend?.graph_display_types?.find((frontendValue) => frontendValue.key === key);
            if (dt) {
              (dt.value as GraphDisplayTypes).updatePeriod = null;
            }
          }

          if (displayType === DisplayType.dynamic && updatePeriod) {
            // Обновляем displayType в pageMap.frontend
            const dt: FrontendValue | undefined = pageMap?.frontend?.graph_display_types?.find(
              (frontendValue) => frontendValue.key === key
            );
            if (dt) {
              (dt.value as GraphDisplayTypes).updatePeriod = updatePeriod;
              dt.hash = getObjectKey(dt.value);
            }
            // Обновляем период дат
            updatePeriodDatesForDynamicDisplayTypes(updatePeriod, key, filterValues);
          }
        }
      });

      const preparedData = prepareDataGraphs(filterValues);
      const cleanFrontend = clearFrontend(preparedData, {
        ...pageMap?.frontend,
        hidden_chart_series: hiddenChartSeries,
      });

      if (id && pageLoaded) {
        debounce(() => {
          updatePage(
            id,
            {
              page_map: {
                layout: {},
                graphs: preparedData,
                frontend: cleanFrontend,
              },
            },
            true
          ).then(({ data }) => {
            dispatch(updatePageAction(id, data));
            setIsAnalyticLoading(false);
          });
        }, 500);
      } else {
        setIsAnalyticLoading(false);
      }
    }
  }, [id, dispatch, debounce, pageLoaded, filterValues]);

  // TODO Не этот
  useEffect(() => {
    if (windowSize.width > 0 && windowSize.width <= 768) {
      setIsVisibleLeftPanel(false);
      setIsVisibleRightPanel(false);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (id && !pageMap && filters) {
      fetchPageData(id, filters);
    }
  }, [id, filters, pageMap, fetchPageData]);

  // Updating data timer for dynamic display type charts
  useEffect(() => {
    const interval = window.setInterval(() => {
      const updatedPeriods = displayTypes
        ?.filter((type) => (type.value as GraphDisplayTypes).displayType === DisplayType.dynamic)
        .map((type) => (type.value as GraphDisplayTypes).updatePeriod);

      const minUpdatedPeriod = getMinUpdatedPeriod(updatedPeriods);

      if (minUpdatedPeriod && Date.now() >= getEndPeriodDate(minUpdatedPeriod)) {
        getPageById(id, true).then(({ data }) => {
          dispatch(updatePageAction(id, data));
        });
      }
    }, 60 * 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [id, dispatch, displayTypes]);

  const isGroupedCategoriesCharts = useMemo(() => getIsGroupedCategoriesCharts(sortedGraphs), [sortedGraphs]);

  return (
    <AnalyticsWrapper>
      <ToggleLeftPanel onClick={onToggleLeftPanel}>
        {isVisibleLeftPanel ? <LeftOutlined /> : <RightOutlined />}
        <FilterLabel>
          <FilterOutlined />
          {t('filter')}
        </FilterLabel>
      </ToggleLeftPanel>
      <ToggleRightPanel onClick={onToggleRightPanel}>
        {isVisibleRightPanel ? <RightOutlined /> : <LeftOutlined />}
        <FilterLabel>
          <FilterOutlined />
          {t('filter')}
        </FilterLabel>
      </ToggleRightPanel>
      <ReflexContainer orientation="vertical">
        <FilterPanel
          flex={isVisibleLeftPanel ? flexLeftPanel : 0}
          onStopResize={onStopResizeLeftPanel}
          minSize={isVisibleLeftPanel ? 150 : undefined}
          maxSize={350}
        >
          {!isPageLoading ? (
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
              <CommonFiltersForm
                filters={filters?.common_filters.filter((filter) => filter.key !== filters.report_only_filters[0])}
                filtersValues={filterValues || {}}
                isLoading={isLoadFilters}
                setFiltersValues={setFilterValues}
                onChange={fetchFiltersList}
              />
            </Scrollbars>
          ) : (
            <Loader />
          )}
        </FilterPanel>

        {isVisibleLeftPanel && <ReflexSplitter propagate={true} className="r-splitter" />}

        <ContentWrapper>
          {isAnalyticLoading && (
            <AnalyticLoading>
              <Loader />
            </AnalyticLoading>
          )}
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <ContentPadding>
              {hasCharts &&
                sortedGraphs.map((graph, graphIdx) => {
                  if (graph.is_fixed && graph.data?.charts?.[0]) {
                    return (
                      <ChartContainer
                        key={`${graph.layout_id}-${graph.data.charts[0]?.value_axes[0]?.key}-${graphIdx}`}
                        isGroupedCategoriesChart={isGroupedCategoriesCharts[`${graph.layout_id}-${graph.graph.key}`]}
                      >
                        <AnalyticsChart
                          chartId={0}
                          graph={graph}
                          setFilterValues={setFilterValues}
                          pageId={id}
                          filterValues={filterValues}
                          onChangeShowingValuesOnChart={onChangeShowingValuesOnChart}
                          onToggleChartSeries={onToggleChartSeries}
                        />
                      </ChartContainer>
                    );
                  }

                  return graph.data?.charts?.map((chart, idx) => {
                    return (
                      <ChartContainer
                        key={`${graph.layout_id}-${chart.value_axes[0]?.key}-${graphIdx}`}
                        isGroupedCategoriesChart={isGroupedCategoriesCharts[`${graph.layout_id}-${graph.graph.key}`]}
                      >
                        <AnalyticsChart
                          chartId={idx}
                          graph={graph}
                          setFilterValues={setFilterValues}
                          pageId={id}
                          filterValues={filterValues}
                          onChangeShowingValuesOnChart={onChangeShowingValuesOnChart}
                          onToggleChartSeries={onToggleChartSeries}
                        />
                      </ChartContainer>
                    );
                  });
                })}
              {!hasCharts && !isPageLoading && <EmptyContent>{t('emptyAnalyticsPage')}</EmptyContent>}
            </ContentPadding>
          </Scrollbars>
        </ContentWrapper>

        {isVisibleRightPanel && <ReflexSplitter propagate={true} className="r-splitter" />}

        <FilterPanel
          flex={isVisibleRightPanel ? flexRightPanel : 0}
          onStopResize={onStopResizeRightPanel}
          minSize={isVisibleRightPanel ? 250 : undefined}
          maxSize={350}
        >
          {!isPageLoading ? (
            <IndicatorsFiltersForm
              indicators={filters?.indicators}
              filtersValues={filterValues || {}}
              setFiltersValues={setFilterValues}
              onResetIndicators={onResetIndicators}
              displayTypes={displayTypes}
              onChangeDisplayType={onChangeDisplayType}
              isLoading={isLoadFilters}
              onFiltersChange={fetchFiltersList}
            />
          ) : (
            <Loader />
          )}
        </FilterPanel>
      </ReflexContainer>

      <DrawerWrapper
        placement="left"
        closable={true}
        onClose={onToggleLeftPanel}
        visible={isVisibleLeftPanel}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        {!isPageLoading ? (
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <CommonFiltersForm
              filters={filters?.common_filters.filter((filter) => filter.key !== filters.report_only_filters[0])}
              filtersValues={filterValues || {}}
              setFiltersValues={setFilterValues}
              onChange={fetchFiltersList}
              isLoading={isLoadFilters}
            />
          </Scrollbars>
        ) : (
          <Loader />
        )}
      </DrawerWrapper>
      <DrawerWrapper
        placement="right"
        closable={true}
        onClose={onToggleRightPanel}
        visible={isVisibleRightPanel}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        {!isPageLoading ? (
          <IndicatorsFiltersForm
            indicators={filters?.indicators}
            filtersValues={filterValues || {}}
            setFiltersValues={setFilterValues}
            onResetIndicators={onResetIndicators}
            displayTypes={displayTypes}
            onChangeDisplayType={onChangeDisplayType}
            isLoading={isLoadFilters}
            onFiltersChange={fetchFiltersList}
          />
        ) : (
          <Loader />
        )}
      </DrawerWrapper>
    </AnalyticsWrapper>
  );
};

export default Analytics;
